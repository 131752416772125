<template>
    <form>
        <b-overlay :show="loading || groupLoading || menuPointLoading" class="p-1" rounded>
            <div class="row">
                <div class="col-lg-2">
                    <div class="position-sticky-main">
                        <div class="form-check">
                            <label class="form-check-label" for="target-role-groups">{{
                                    $tc('authorization::role_groups.role_group', 1).ucFirst()
                                }}</label>
                            <input
                                id="target-role-groups"
                                v-model="target"
                                class="form-check-input"
                                type="radio"
                                value="roleGroup"
                            />
                        </div>
                        <div class="form-check">
                            <label class="form-check-label" for="target-users">{{
                                    $tc('users.user', 1).ucFirst()
                                }}</label>
                            <input
                                id="target-users"
                                v-model="target"
                                class="form-check-input"
                                type="radio"
                                value="user"
                            />
                        </div>
                        <div v-if="individuals && individuals.length">
                            <label class="form-label"></label>
                            <select
                                v-model="individual"
                                class="form-select"
                            >
                                <option :value="null">{{ $t('base.please_select').ucFirst() }}</option>
                                <option v-for="(item, index) in individuals" :key="index" :value="item">{{
                                        item.name
                                    }}
                                </option>
                            </select>
                        </div>
                        <div class="row my-4">
                            <div class="col-12">
                                <b-button :title="$t('base.save').ucFirst()" variant="primary" @click="save">
                                    {{ $t('base.save').ucFirst() }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-10">
                    <table
                        class="w-100 table-sticky table-borderless table-hover table-border-collapse border table project-list-table table-nowrap align-middle">
                        <thead>
                        <tr class="text-center">
                            <th colspan="2">{{ $tc('menus::menu_points.menu_point', 2).ucFirst() }}</th>
                            <th v-for="(translation_key, colKey) in columns" :key="colKey">
                                {{ $t(translation_key).ucFirst() }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(category, index) in menuPoints" :key="index">
                            <tr class="bg-soft-primary">
                                <td>
                                    <strong>
                                        {{ category.title.toUpperCase() }}
                                    </strong>
                                </td>
                                <td class="bg-light text-center">
                                    <template v-if="category.roles && category.roles.length">
                                        <div v-for="(role, roleIndex) in category.roles" :key="roleIndex" class="form-check d-inline-block">
                                            <input
                                                   v-model="roles" :value="role.id" class="form-check-input"
                                                   type="checkbox"/>
                                        </div>
                                    </template>
                                </td>
                                <td v-for="(translation_key, colKey) in columns" :key="colKey">
                                    <input v-if="showCheckbox(category, translation_key)" v-model="roles"
                                           :value="getCheckboxValue(category, translation_key)" type="checkbox"/>
                                </td>
                            </tr>
                            <template v-if="category.menu_points && category.menu_points.length">
                                <role-matrix-menu-point
                                    v-for="(menuPoint, mpIndex) in category.menu_points"
                                    :key="mpIndex"
                                    v-model="roles"
                                    :category="category"
                                    :columns="columns"
                                    :menu-point="menuPoint"
                                    @add="addToRoles($event)"
                                    @fresh="change"
                                    @remove="removeFromRoles($event)"
                                ></role-matrix-menu-point>
                            </template>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import RoleMatrixMenuPoint from "../elements/role-matrix-menu-point.vue";

export default {
    name: "RoleMatrixForm",

    emits: ['saved'],

    components: {RoleMatrixMenuPoint},

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'user/loading',
            'groupLoading': 'roleGroup/loading',
            'states': 'user/states',
            'errors': 'user/errors',
            'menuPoints': 'menuPoint/matrix',
            'menuPointLoading': 'menuPoint/loading',
            'roles': 'role/all',
            groups: 'roleGroup/all',
            users: 'user/list',
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        columns: function () {
            // TODO hiányoznak az auth csomag seederéből

            return this.pluck(this.pluck(this.menuPoints, 'related_roles', 'menu_points'), 'roles')
                .map(item => this.$t(item.translation_key).ucFirst())
                .filter((value, index, array) => array.indexOf(value) === index)
        },

        savable: function () {
            return this.roles.concat(this.menuPointsRoles)
        },

        individuals: function () {
            switch (this.target) {
                case 'roleGroup':
                    if (!this.groups || !this.groups.length) {
                        this.$store.dispatch('roleGroup/all', {with: ['roles']})
                    }
                    return this.groups
                case 'user':
                    if (!this.users || !this.users.length) {
                        this.$store.dispatch('user/list', {with: ['roles', 'roleGroups']})
                    }
                    return this.users
                default:
                    return []
            }
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.user ?? {})),
            roles: [],
            menuPointsRoles: [],
            target: null,
            individual: null
        }
    },

    methods: {
        addToRoles: function (role) {
            if (this.roles.includes(role)) {
                return
            }

            this.roles.push(role);
        },

        removeFromRoles: function (role) {
            const index = this.roles.findIndex(item => item == role);

            this.roles.splice(index, 1)
        },

        change: function ($event) {
            if ($event.target.checked) {
                this.addToRoles(Number($event.target.value))
            } else if (!$event.target.checked) {
                this.removeFromRoles(Number($event.target.value))
            }
        },

        showCheckbox: function (item, tk) {
            return item.related_roles && item.related_roles[0] && item.related_roles[0].roles.find(role => role.translated_authority.toLowerCase() === tk.toLowerCase())
        },

        getCheckboxValue: function (item, tk) {
            const role = item.related_roles && item.related_roles[0] && item.related_roles[0].roles.find(role => role.translated_authority.toLowerCase() === tk.toLowerCase())

            if (!role) {
                return null;
            }

            return role.id;
        },

        save: function () {
            const data = {id: this.individual.id, roles: this.roles}

            this.$store.dispatch(`${this.target}/roles`, data)
        },

        pluck: function (array, target, recursive) {
            const result = [];

            if (!array || !Array.isArray(array) || !array.length) {
                return result;
            }

            array.forEach(item => {
                if (item[target] && item[target].length) {
                    result.push(...item[target])
                }

                if (recursive && item[recursive] && item[recursive].length) {
                    const subResult = this.pluck(item[recursive], target, recursive)

                    if (subResult && subResult.length) {
                        result.push(...subResult)
                    }
                }
            });

            return result;
        },

        initRoles: function () {
            if (!this.individual || !this.individual.roles || !this.individual.roles.length) {
                this.roles = []
            } else {
                this.roles = this.individual.roles.map(role => role.id);
                this.menuPointsRoles = this.individual.roles.map(role => role.id)
            }
        },
    },

    watch: {
        menu: function () {
            this.model = JSON.parse(JSON.stringify(this.user))
        },

        target: function () {
            this.individual = null
            this.roles = []
        },

        individual: function () {
            this.initRoles()
        },
    },

    created() {
        this.$store.dispatch('user/clearList')
        // console.log(this.all)
        // // console.log(this.user, this.roles)
    }
}
</script>

<style scoped>
</style>
