<script>
export default {
    name: 'RoleMatrixMenuPoint',

    props: {
        modelValue: {
            type: Array
        },
        category: {
            type: Object
        },
        parent: {
            type: Object
        },
        menuPoint: {
            type: Object
        },
        columns: {},
        level: {
            type: [Number],
            default: 1
        }
    },

    emits: ['add', 'remove', 'fresh'],

    data: function () {
        return {
            roles: []
        }
    },

    computed: {
        hasChildren: function () {
            return this.menuPoint.menu_points && this.menuPoint.menu_points.length
        },

        localRoles: {
            get: function () {
                return JSON.parse(JSON.stringify(this.modelValue))
            },
            set: function () {
                // console.log(value)
            },
        },

        relatedRoles: function () {
            const roles = {}

            this.columns.forEach(column => {
                roles[column] = this.menuPoint.related_roles.map(related => related.roles.find(role => role.translated_authority.toLowerCase() === column.toLowerCase()))
            })

            return roles
        },
    },

    methods: {
        /*addToRoles: function (role) {
            const old = [...this.roles]
            this.roles.push(role)
            this.fresh(this.roles, old)
        },

        removeFromRoles: function (role) {
            const old = [...this.roles]
            const index = this.roles.findIndex(item => item == role);

            this.roles.splice(index, 1)
            this.fresh(this.roles, old)
        },*/

        showCheckbox: function (item, tk) {
            return item.related_roles && item.related_roles.length && item.related_roles.some(related => related.roles.find(role => role.translated_authority.toLowerCase() === tk.toLowerCase()))
        },

        getRoles: function (item, tk) {
            const relatedRoles = item.related_roles.map(related => related.roles.find(role => role.translated_authority.toLowerCase() === tk.toLowerCase()))

            if (!relatedRoles) {
                return []
            }

            return relatedRoles
        },

        getCheckboxValue: function (item, tk) {
            const role = item.related_roles && item.related_roles[0] && item.related_roles[0].roles.find(role => role.translated_authority.toLowerCase() === tk.toLowerCase())

            if (!role) {
                return null;
            }

            return  role.id;
        },

        /*fresh: function (now, old) {
            const added = now.filter(item => !old.includes(item))
            const removed = old.filter(item => !now.includes(item))

            if (added.length) {
                this.$emit('add', added[0])
            }

            if (removed.length) {
                this.$emit('remove', removed[0])
            }
        },*/

        fresh: function ($event) {
            this.$emit('fresh', $event)
        },
    },

    /*watch: {
        modelValue: function (value) {
            this.roles = value
        },
    },*/

    created() {
        // // console.log(this.menuPoint)
    }
}
</script>

<template>
    <tr>
        <td>
            <i v-if="menuPoint.icon && level === 1" :class="menuPoint.icon"></i>
            <span :class="'menu-points-'+level">{{ menuPoint.title.ucFirst() }}</span>
        </td>
        <td class="bg-light text-center">
            <div class="form-check d-inline-block">
                <input v-if="menuPoint.roles && menuPoint.roles.length" v-model="localRoles" :value="menuPoint.roles[0].id" class="form-check-input" type="checkbox" @input="fresh"/>
            </div>
        </td>
        <td class="text-center" v-for="(translation_key, colKey) in columns" :key="colKey">
            <template v-if="showCheckbox(menuPoint, translation_key)">
                <div v-for="(role) in relatedRoles[translation_key]" :key="role.id" class="form-check d-inline-block" :class="{'d-block': relatedRoles[translation_key].length > 1, 'd-inline-block': relatedRoles[translation_key].length < 2}">
                    <span v-if="relatedRoles[translation_key].length > 1">{{ role.category.translated_name.ucFirst() }}</span>
                    <input v-model="localRoles" :value="role.id" class="form-check-input" type="checkbox" @input="fresh"/>
                </div>
            </template>
        </td>
    </tr>
    <template v-if="hasChildren">
        <role-matrix-menu-point
            v-for="(child, index) in menuPoint.menu_points"
            :key="index"
            :category="category"
            :parent="menuPoint"
            :menu-point="child"
            :columns="columns"
            :level="level + 1"
            v-model="localRoles"
            @fresh="fresh"
        ></role-matrix-menu-point>
    </template>
</template>

<style>
    .menu-points-1 {
        padding-left: 10px;
        font-weight: 500;
    }

    .menu-points-2 {
        padding-left: 20px;
        font-size: 13px;
    }

    .menu-points-3 {
        padding-left: 30px;
        font-size: 12px;
    }
</style>
