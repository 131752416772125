<template>
    <role-matrix-form></role-matrix-form>
</template>

<script>
import RoleMatrixForm from "../../../components/forms/role-matrix-form.vue";

export default {
    components: {RoleMatrixForm},

    created() {
        this.$store.dispatch('role/all')
        this.$store.dispatch('menuPoint/matrix')
    }
}
</script>

<style scoped>

</style>
